
import Vue from "vue";
import LayoutTopMenu from "@/layouts/LayoutTopMenu.vue";
import { Task, RealEstate } from "../types";
import BaseDataView from "@/components/BaseDataView.vue";
import BaseTable from "@/components/BaseTable.vue";
import RangeDatePicker from "@/components/RangeDatePicker.vue";
import GroupedSelect from "@/components/GroupedSelect.vue";
import moment from "moment";
import debounce from "lodash/debounce";

const Component = Vue.extend({
  created(): void {
    this.$emit("update:layout", LayoutTopMenu);
    this.$store.dispatch("task/getDateFilteredTasks", this.dateRange);
    this.$store.dispatch("realEstate/getAllRealEstates");
  },
  watch: {},
  computed: {
    tasks(): Task[] {
      return this.$store.getters["task/tasksFiltered"](this.filters);
    },
    tasksLoading(): boolean {
      return this.$store.getters["task/isLoading"];
    },
    realEstates(): RealEstate[] {
      return this.$store.getters["realEstate/realEstates"];
    },
    realEstatesLoading(): boolean {
      return this.$store.getters["realEstate/isLoading"];
    }
  },
  methods: {
    setFilters: debounce(function(this: any, filters: any): void {
      this.filters = filters;
    }, 500),
    setDateRange(range: { from: Date; to: Date }) {
      this.dateRange = range;
      this.$store.dispatch("task/getDateFilteredTasks", this.dateRange);
    },
    setRealEstateFilter(realEstates: string[]) {
      this.$set(this.filters, "realEstate.id", realEstates);
    }
  },
  data() {
    const dateFrom = moment();
    dateFrom.set({ hour: 0, minute: 0, second: 0 });
    dateFrom.subtract(1, "year");
    const dateTo = moment();
    dateTo.set({ hour: 23, minute: 59, second: 59 });
    return {
      pagination: {
        itemsPerPage: 50
      },
      filters: {},
      dateRange: { from: dateFrom.toDate(), to: dateTo.toDate() },
      headers: [
        {
          text: "OCCURRENCE.REAL_ESTATE",
          value: "realEstate.street",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "FILTER.REPORT",
          value: "document.title",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "FILTER.RECORDED",
          value: "creationDate",
          align: "left",
          filterable: true,
          sortable: true
        }
      ]
    };
  },
  components: {
    "base-table": BaseTable,
    "range-date-picker": RangeDatePicker,
    "grouped-select": GroupedSelect,
    "base-data-view": BaseDataView
  }
});
export default Component;
