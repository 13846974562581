import { Api } from "@/services/api";
import { User } from "@/types";

const getUserData = (user: User) => {
  const data: any = user;
  // for (const prop of Object.keys(data)) {
  //   if (data[prop] === undefined || data[prop] === "") {
  //     delete data[prop];
  //   }
  // }
  return data;
};
export default {
  async getAllUsers(): Promise<User[]> {
    let response;
    let data;
    try {
      response = await Api.request().get("/api/accounts/get");
      data = Api.handleResponse(response);
    } catch (err) {
      throw err;
    }

    return data;
  },
  async addUser(user: User): Promise<void> {
    // const userData = getUserData(user);
    try {
      const response = await Api.request().post("/api/auth/register", user);
      Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
  },
  async removeUser(id: number): Promise<void> {
    try {
      const response = await Api.request().delete(`/api/accounts/${id}`);
      Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
  },
  async updateUser(user: User): Promise<void> {
    const userData = getUserData(user);
    try {
      const response = await Api.request().put(
        `/api/accounts/${user.id}`,
        userData
      );
      Api.handleResponse(response);
    } catch (err) {
      throw err;
    }
  },
};
